
.img-view[data-v-7980877f] {
    width: 100%;
    height: 100%;
  /* border-radius: 8px; */
  cursor: pointer;
}
.choose-img[data-v-7980877f] {
  background-color: #F5F5F5;
}
.choose-img img[data-v-7980877f] {
  width: 12px;
  height: 12px;
  margin-right: 5px;
      margin-top: -3px;
}
.del-content[data-v-7980877f] {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 4px;
  cursor: pointer;
}
.del-content img[data-v-7980877f] {
  width: 18px;
  height: 18px;
}
.memo[data-v-7980877f]{
	font-size: 14px;
	color: #909090;
	padding-left: 20px;
}
.file-icon[data-v-7980877f]{
	width: 14px;
	height: 17px;
	    margin-left: 10px;
}
.file-item[data-v-7980877f]{
	background-color: #FFFFFF;
	background-image: none;
	border-radius: 4px;
	border: 1px solid #DCDFE6;
}
.file-del[data-v-7980877f]{
	width: 14px;
	height: 14px;
	margin-right: 8px;
	opacity: 0;
	cursor: pointer;
}
.file-item:hover .file-del[data-v-7980877f]{
	opacity: 1;
}

	

